import { FilterOptions } from '@/types';
import { TZDate } from '@date-fns/tz';
import { endOfDay, startOfDay } from 'date-fns';

const formatterInputSendValue = (value: string) =>
  !!value && value.length ? value?.split(';').map((part) => part.trim()) : undefined;

const formatterInputMultiValue = (value: string[]): string[] =>
  value?.map((v) => v.toString()).join(';') as unknown as string[];

const formattedBaseMediaSourceValue = (value: string[]): string[] =>
  value?.map((v) => v.toString()).join(',') as unknown as string[];

export const formatterDefaultValues = (data: FilterOptions) => {
  if (!data) {
    return undefined;
  }

  return {
    ...data,
    cpCode: formatterInputMultiValue(data.cpCode),
    assistants: formatterInputMultiValue(data.assistants),
    baseMediaSource: formattedBaseMediaSourceValue(data.baseMediaSource),
  };
};

export const formatterFilter = (data: FilterOptions) => {
  const formattedFilter: FilterOptions = {
    ...data,
    cpCode: formatterInputSendValue(data.cpCode as unknown as string),
    assistants: formatterInputSendValue(data.assistants as unknown as string),
    startDate: data.startDate
      ? startOfDay(new TZDate(data.startDate, 'America/Sao_Paulo'))
      : undefined,
    endDate: data.endDate ? endOfDay(new TZDate(data.endDate, 'America/Sao_Paulo')) : undefined,
    baseMediaSource:
      data.baseMediaSource &&
      !Array.isArray(data.baseMediaSource) &&
      (data.baseMediaSource as unknown as string).split(','),
  };

  const cleanupFilter = Object.entries(formattedFilter).reduce((acc, [key, value]) => {
    if (
      value !== undefined &&
      value !== 'undefined' &&
      value &&
      (!Array.isArray(value) || value.length > 0)
    ) {
      acc[key as keyof FilterOptions] = value;
    }
    return acc;
  }, {} as FilterOptions);

  return cleanupFilter;
};
